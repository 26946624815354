<template>
  <section class="new_css">
    <!--工具条-->
    <el-row class="new_header">
      <el-col
        v-if="buttonList.length > 0"
        :span="24"
        class="organizetoolbar"
        style="padding-bottom: 0px"
      >
        <el-form
          :inline="true"
          ref="selectForm"
          :model="selectForm"
          class="select_form"
        >
          <el-form-item label="名称:">
            <el-input
              v-model="selectForm.Username"
              placeholder="名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="面试公司:">
            <el-input
              v-model="selectForm.Factory"
              placeholder="面试公司"
            ></el-input>
          </el-form-item>
          <el-form-item label="岗位:">
            <el-input v-model="selectForm.Post" placeholder="岗位"></el-input>
          </el-form-item>
          <el-form-item label="是否通过:">
            <el-select v-model="selectForm.result" placeholder="是否通过">
              <el-option
                v-for="item in passedLists"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <toolbar
            :buttonList="buttonList"
            @callFunction="callFunction"
            :buttonListmsg="buttonListmsg"
          ></toolbar>
        </el-form>
      </el-col>
    </el-row>
    <!-- <toolbar :buttonList="buttonList" @callFunction="callFunction" :buttonListmsg="buttonListmsg"></toolbar> -->
    <!--列表-->

    <!-- <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar> -->
    <!--列表-->
    <div class="calc-height">
      <el-table
        :data="users"
        highlight-current-row
     
        height="100%"
        @current-change="selectCurrentRow"
        @row-dblclick="handleShow"
        class="new_table"
      >
        <el-table-column prop="UserInfoUserName" label="姓名">
        </el-table-column>
        <el-table-column
          label="面试日期"
          prop="TalkDate"
          :formatter="formatStartTime"
        ></el-table-column>
        <el-table-column label="面试公司" prop="Factory"></el-table-column>
        <el-table-column prop="Post" label="面试岗位"> </el-table-column>
        <el-table-column prop="IsPassed" label="是否通过">
          <template slot-scope="scope">
            <el-tag
              :type="scope.row.IsPassed == '通过' ? 'success' : 'danger'"
              disable-transitions
            >
              {{ scope.row.IsPassed }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="UserInfoApprovalState" label="状态">
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页器 -->
    <el-pagination
      align="center"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-size="pageSize"
      layout="prev, pager, next, jumper,total"
      :page-count="total"
      :total="totaldata"
    >
    </el-pagination>
    <el-dialog
      title="试用"
      :visible.sync="permissionVisible"
      v-model="permissionVisible"
      :close-on-click-modal="false"
    >
      <el-form
        label-width="100px"
        :rules="postRules"
        ref="PermissionForm"
        :model="PermissionForm"
        class="demo-form-inline"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="岗位:" prop="Post">
              <el-input
                type="text"
                :disabled="true"
                v-model="PermissionForm.Post"
              >
                <el-button
                  slot="append"
                  icon="el-icon-search"
                  @click="chooseUser"
                ></el-button>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="伙食日单价:" prop="EateDaySalary">
              <el-select
                v-model="PermissionForm.EateDaySalary"
                placeholder="伙食日单价"
                style="width: 100%"
              >
                <el-option
                  v-for="item in EateSalaryLists"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="12">
            <el-form-item prop="StartDate" label="开始日期:">
              <el-date-picker
                type="date"
                v-model="PermissionForm.StartDate"
                value-format="yyyy-MM-dd"
                placeholder="选择日期"
              ></el-date-picker>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="试用期:" prop="ProbationExplain">
              <el-select
                v-model="PermissionForm.ProbationExplain"
                placeholder="试用期"
                @change="ProbationExplainModel($event)"
                style="width: 100%"
              >
                <el-option
                  v-for="item in ProbationExplainLists"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item
              label="是否有年功:"
              prop="IsYear"
              :rules="[
                { required: true, message: '不能为空', trigger: 'blur' },
              ]"
            >
              <el-select
                v-model="PermissionForm.IsYear"
                placeholder="是否有年功"
                style="width: 100%"
              >
                <el-option label="是" value="是"></el-option>
                <el-option label="否" value="否"></el-option>
                <el-option label="超龄年功" value="超龄年功"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="超龄年功工资:" prop="OverAgeUserAvailable">
              <el-input
                type="text"
                v-model="PermissionForm.OverAgeUserAvailable"
                @blur="inputMoney('OverAgeUserAvailable')"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="日保健费:">
              <el-select
                v-model="PermissionForm.CareDaySalary"
                placeholder="日保健费"
                style="width: 100%"
              >
                <el-option
                  v-for="item in CareDaySalaryLists"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="驻外补贴:">
              <el-select
                v-model="PermissionForm.OutsideSalary"
                placeholder="驻外补贴"
                style="width: 100%"
              >
                <el-option
                  v-for="item in OutsideSalaryLists"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="值班日保健费:" prop="OndutyCareSalary">
              <el-input
                type="text"
                v-model="PermissionForm.OndutyCareSalary"
                @blur="inputMoney('OndutyCareSalary')"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="工人系数:" prop="WorkerMod">
              <el-input
                type="text"
                v-model="PermissionForm.WorkerMod"
                @blur="inputMoney('WorkerMod')"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="租房补贴:" prop="HouseSalary">
              <el-input
                type="text"
                v-model="PermissionForm.HouseSalary"
                @blur="inputMoney('HouseSalary')"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="绩效工资:" prop="PerfSalary">
              <el-input
                type="text"
                v-model="PermissionForm.PerfSalary"
                @blur="inputMoney('PerfSalary')"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="特岗补贴:">
              <el-input
                v-model="PermissionForm.SpecialSalary"
                @blur="inputMoney('SpecialSalary')"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="转正待遇:">
              <el-input
                v-model="PermissionForm.PositiveExplain"
                @blur="inputMoney('PositiveExplain')"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="车辆补贴:">
              <el-input
                v-model="PermissionForm.BusMoney"
                @blur="inputMoney('BusMoney')"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="岗位工资:" prop="WorkSalary">
              <el-input
                v-model="PermissionForm.WorkSalary"
                @blur="inputMoney('WorkSalary')"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="爱心基金:">
              <el-input
                v-model="PermissionForm.LoveMoney"
                @blur="inputMoney('LoveMoney')"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="日工资:">
              <el-input
                v-model="PermissionForm.DaySalary"
                @blur="inputMoney('DaySalary')"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="银行:">
              <el-input v-model="PermissionForm.BankCardName"></el-input>
            </el-form-item>
          </el-col>

          <!-- <el-col :span="12">
      <el-form-item  label="开户行:">
			  <el-input  v-model="PermissionForm.BankAddress"></el-input>
	</el-form-item>
   </el-col>		   -->
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="银行卡号:">
              <el-input v-model="PermissionForm.BankCardCD"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="结束日期:">
              <el-date-picker
                type="date"
                :disabled="true"
                v-model="PermissionForm.EndDate"
                value-format="yyyy-MM-dd"
                placeholder="选择日期"
              ></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="薪资类型:" prop="SalaryType">
              <el-select
                v-model="PermissionForm.SalaryType"
                @change="SalaryTypeModel($event)"
                placeholder="薪资类型"
                style="width: 100%"
              >
                <el-option
                  v-for="item in SalaryTypeLists"
                  :key="item.Id"
                  :label="item.Type"
                  :value="item.Type"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="职位待遇:" prop="PostSalaryName">
              <el-select
                v-model="PermissionForm.PostSalaryName"
                @change="PositionModel($event)"
                placeholder="职位待遇"
                style="width: 100%"
              >
                <el-option
                  v-for="item in PositionLists"
                  :key="item.Id"
                  :label="item.LeaveName"
                  :value="item.LeaveName"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item
              label="选择考勤组"
              label-width="120px"
              prop="AttendGroupName"
              :rules="[
                { required: true, message: '不能为空', trigger: 'blur' },
              ]"
            >
            <el-input
                type="text"
                :disabled="true"
                v-model="PermissionForm.AttendGroupName"
              >
                <el-button
                  slot="append"
                  icon="el-icon-search"
                  @click="selectAddress"
                ></el-button>
              </el-input>
           
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="备注:" prop="Remark">
              <el-input type="textarea" v-model="PermissionForm.Remark">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <upload-files
          :files="PermissionForm.FileArry"
          :key="key"
          action="/cyl/ftp/ftp/upload"
          :limit="20"
          @fun="dealFiles"
        ></upload-files>
        <el-row>
          <el-col :span="24" style="text-align: center">
          
              <el-button
                type="primary"
                v-on:click="onPermissionSubmit('PermissionForm')"
                >提交</el-button
              >
          
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
    <el-dialog
      :visible.sync="addUserVisible"
      v-model="addUserVisible"
      width="75%"
      center
      append-to-body
    >
      <UserChoosejob
        :data="choosedusers"
        :all="true"
        :single="false"
        @callback="usercallFunction"
      ></UserChoosejob>
    </el-dialog>
    <el-dialog
      :title="operation ? '修改' : '查看'"
      :visible.sync="interviewVisible"
      v-model="interviewVisible"
      :close-on-click-modal="false"
      width="60%"
    >
        <el-form label-width="100px"
                 :rules="rules"
                 ref="InterviewForm"
                 :model="InterviewForm"
                 class="demo-form-inline">
            <el-row>
                <el-col :span="8">
                    <el-form-item label="面试公司:" prop="Factory">
                        <el-select v-model="InterviewForm.Factory"
                                   @change="selectModel($event)"
                                   filterable
                                   placeholder="面试公司"
                                   style="width: 100%">
                            <el-option v-for="item in OrganizeLists"
                                       :key="item.ID"
                                       :label="item.label"
                                       :value="item.Name"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="面试岗位:" prop="Post">
                        <el-input v-model="InterviewForm.Post"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="岗位类型:" prop="PostType">
                        <el-select v-model="InterviewForm.PostType"
                                   placeholder="岗位类型"
                                   style="width: 100%">
                            <el-option v-for="item in positionLists"
                                       :key="item.value"
                                       :label="item.label"
                                       :value="item.value"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row>
                <el-col :span="12">
                    <el-form-item prop="TalkDate" label="面试时间">
                        <el-date-picker type="date"
                                        v-model="InterviewForm.TalkDate"
                                        value-format="yyyy-MM-dd"
                                        placeholder="选择日期"></el-date-picker>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="面试人员:" prop="TalkPersons">
                        <el-input type="text"
                                  :disabled="true"
                                  v-model="InterviewForm.TalkPersons">
                            <!-- <el-button slot="append" icon="el-icon-search" @click="chooseUser"></el-button> -->
                        </el-input>
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row>
                <el-col :span="12">
                    <el-form-item label="基础能力:" prop="BasicAbility">
                        <el-input placeholder="请输入内容"
                                  v-model="InterviewForm.BasicAbilityExp"
                                  class="input-with-select">
                            <el-select v-model="InterviewForm.BasicAbility"
                                       slot="prepend"
                                       style="width: 80px"
                                       placeholder="选择">
                                <el-option v-for="item in BasicLists"
                                           :key="item.value"
                                           :label="item.label"
                                           :value="item.value"></el-option>
                            </el-select>
                        </el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="专业知识技能:" prop="ProfessionalAbility">
                        <el-input placeholder="请输入内容"
                                  v-model="InterviewForm.ProfessionalAbilityExp"
                                  class="input-with-select">
                            <el-select v-model="InterviewForm.ProfessionalAbility"
                                       slot="prepend"
                                       style="width: 80px"
                                       placeholder="选择">
                                <el-option v-for="item in BasicLists"
                                           :key="item.value"
                                           :label="item.label"
                                           :value="item.value"></el-option>
                            </el-select>
                        </el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="相关工作经验:" prop="WorkAbility">
                        <el-input placeholder="请输入内容"
                                  v-model="InterviewForm.WorkAbilityExp"
                                  class="input-with-select">
                            <el-select v-model="InterviewForm.WorkAbility"
                                       slot="prepend"
                                       style="width: 80px"
                                       placeholder="选择">
                                <el-option v-for="item in BasicLists"
                                           :key="item.value"
                                           :label="item.label"
                                           :value="item.value"></el-option>
                            </el-select>
                        </el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="办公软件使用能力:" prop="OfficeAbility">
                        <el-input placeholder="请输入内容"
                                  v-model="InterviewForm.OfficeAbilityExp"
                                  class="input-with-select">
                            <el-select v-model="InterviewForm.OfficeAbility"
                                       slot="prepend"
                                       style="width: 80px"
                                       placeholder="选择">
                                <el-option v-for="item in BasicLists"
                                           :key="item.value"
                                           :label="item.label"
                                           :value="item.value"></el-option>
                            </el-select>
                        </el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="沟通能力表达:" prop="CommunicateAbility">
                        <el-input placeholder="请输入内容"
                                  v-model="InterviewForm.CommunicateAbilityExp"
                                  class="input-with-select">
                            <el-select v-model="InterviewForm.CommunicateAbility"
                                       slot="prepend"
                                       style="width: 80px"
                                       placeholder="选择">
                                <el-option v-for="item in BasicLists"
                                           :key="item.value"
                                           :label="item.label"
                                           :value="item.value"></el-option>
                            </el-select>
                        </el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="专业软件使用能力:" prop="ProfessionalSoftware">
                        <el-input placeholder="请输入内容"
                                  v-model="InterviewForm.ProfessionalSoftwareExp"
                                  class="input-with-select">
                            <el-select v-model="InterviewForm.ProfessionalSoftware"
                                       slot="prepend"
                                       style="width: 80px"
                                       placeholder="选择">
                                <el-option v-for="item in BasicLists"
                                           :key="item.value"
                                           :label="item.label"
                                           :value="item.value"></el-option>
                            </el-select>
                        </el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="发展潜力:" prop="PotentialAbilityExp">
                        <el-input placeholder="请输入内容"
                                  v-model="InterviewForm.PotentialAbility"
                                  class="input-with-select">
                            <el-select v-model="InterviewForm.PotentialAbility"
                                       slot="prepend"
                                       style="width: 80px"
                                       placeholder="选择">
                                <el-option v-for="item in BasicLists"
                                           :key="item.value"
                                           :label="item.label"
                                           :value="item.value"></el-option>
                            </el-select>
                        </el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="仪容仪表:" prop="DressUpExp">
                        <el-input placeholder="请输入内容"
                                  v-model="InterviewForm.DressUpExp"
                                  class="input-with-select">
                            <el-select v-model="InterviewForm.DressUp"
                                       slot="prepend"
                                       style="width: 80px"
                                       placeholder="选择">
                                <el-option v-for="item in BasicLists"
                                           :key="item.value"
                                           :label="item.label"
                                           :value="item.value"></el-option>
                            </el-select>
                        </el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="价值观/态度:" prop="AttitudeExp">
                        <el-input placeholder="请输入内容"
                                  v-model="InterviewForm.AttitudeExp"
                                  class="input-with-select">
                            <el-select v-model="InterviewForm.Attitude"
                                       slot="prepend"
                                       style="width: 80px"
                                       placeholder="选择">
                                <el-option v-for="item in BasicLists"
                                           :key="item.value"
                                           :label="item.label"
                                           :value="item.value"></el-option>
                            </el-select>
                        </el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="其他:">
                        <el-input placeholder="请输入内容"
                                  v-model="InterviewForm.Remark"
                                  class="input-with-select">
                        </el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="整体匹配度:">
                        <el-select v-model="InterviewForm.MatchRate" placeholder="整体匹配度" style="width: 100%">
                            <el-option v-for="item in matchRateLists" :key="item.value" :label="item.label" :value="item.value"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="建议:">
                        <el-select v-model="InterviewForm.Recommendation" placeholder="建议" style="width: 100%">
                            <el-option v-for="item in RecommendationLists" :key="item.value" :label="item.label" :value="item.value"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="面试结果:" prop="IsPassed">
                        <el-select v-model="InterviewForm.IsPassed" placeholder="面试结果" style="width: 100%">
                            <el-option v-for="item in passedLists" :key="item.value" :label="item.label" :value="item.value"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="前公司薪资待遇:" prop="OldFactorySalary"
                                  :rules="[{ required: true, message: '请填写前公司薪资待遇', trigger: 'blur' },]">
                        <el-input type="text" v-model="InterviewForm.OldFactorySalary" @blur="inputMoney('OldFactorySalary')"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="24">
                    <label for="Summarize" class="el-form-item__label"><span style="color: red">*</span>面试总结:</label>
                    <el-input type="textarea" v-model="InterviewForm.Summarize" style="width: 90%"></el-input>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="24">
                    <label for="OldFactoryWelfare" class="el-form-item__label"><span style="color: red">*</span>前公司福利待遇:</label>
                    <el-input type="textarea" v-model="InterviewForm.OldFactoryWelfare" style="width: 90%"></el-input>
                </el-col>
            </el-row>
            <el-row v-if="operation">
                <el-col :span="24" style="text-align: center">
                    <el-button type="primary" v-on:click="onSubmit('InterviewForm')">提交</el-button>

                </el-col>
            </el-row>
        </el-form>
    </el-dialog>
    <el-dialog
      :visible.sync="addressVisible"
      v-model="addressVisible"
      width="75%"
      title="考勤地点"
      center
      append-to-body
    >
      <el-table
        ref="multipleTable1"
        :data="AreaData"
          height="300"
       highlight-current-row
        style="width: 100%"
        @current-change="handleSelectionChange1"
      >
        <el-table-column prop="Name" label="考勤组名称"> </el-table-column>
        <!-- <el-table-column label="类型" prop="Type"></el-table-column> -->
      </el-table>
      <div style="margin-top: 20px">
        <el-button v-on:click="areaSelection">确认选择</el-button>
      </div>
    </el-dialog>
  </section>
</template>
<script>
import util from "../../../util/date";
import * as validate from "../../../util/validate";
import {
  GetInterviewPageList,
  getInfoList,
  GetUserDis,
  AddProbation,
  getOrganizeList,
  UpdateInterview,
  GetDepartmentOrg,
} from "../../api/hr";
import { getButtonList } from "../../promissionRouter";
import Toolbar from "../../components/newToolbar";
import UserChoosejob from "../../components/UserChoosejob";
import UploadFiles from "../../components/UploadFiles";
export default {
  components: { Toolbar, UserChoosejob, UploadFiles },
  data() {
    return {
      key: 0,
      loading: true,
      currentPage: 1, // 当前页码
      total: null, // 总条数
      totaldata: null,
      pageSize: 20, // 每页的数据条数
      page: 1,
      searchVal: "",
      permissionVisible: false,
      addUserVisible: false,
      interviewVisible: false,
      filters: {
        name: "",
      },
      multipleSelection1:[],
      AreaData:[],
      addressVisible: false,
      operation: false,
      OrganizeLists: [],
      positionLists: [],
      InterviewForm: [],
      choosedusers: [],
      PositionLists: [],
      SalaryTypeLists: [],
      selectForm: {
        Factory: "",
        Post: "",
        result: "",
        Username: "",
      },
      buttonListmsg: "",
      users: [],
      buttonList: [],
      passedLists: [
        {
          value: "通过",
          label: "通过",
        },
        {
          value: "未通过",
          label: "未通过",
        },
      ],
      PermissionForm: {
        UserName: "",
        userId: "",
        Post: "",
        PostId: "",
        StartDate: "",
        EndDate: "",
        SpecialSalary: "0",
        EateDaySalary: "",
        CareDaySalary: "0",
        OutsideSalary: "0",
        ProbationExplain: "",
        PositiveExplain: "",
        BusMoney: "",
        WorkSalary: "",
        LoveMoney: "",
        DaySalary: "",
        BankCardName: "",
        BankAddress: "",
        BankCardCD: "",
        PostSalaryId: "",
        PostSalaryName: "",
        SalaryType: "",
        SalaryTypeId: "",
        InterviewId: "",
        FileArry: [],
        IsYear: null,
        OverAgeUserAvailable: 0,
        OndutyCareSalary: 0,
        Remark: "",
        DptId: "",
        PerfSalary: 0,
        HouseSalary: 0,
        WorkerMod: 0,
        JobType: "",
        AttendGroupName:"",
      },
      EateSalaryLists: [
        {
          value: "0",
          label: "0",
        },
        {
          value: "5",
          label: "5",
        },
        {
          value: "10",
          label: "10",
        },
        {
          value: "25",
          label: "25",
        },
        {
          value: "30",
          label: "30",
        },
      ],
      CareDaySalaryLists: [
        {
          value: "0",
          label: "0",
        },
        {
          value: "5",
          label: "5",
        },
        {
          value: "10",
          label: "10",
        },
      ],
      OutsideSalaryLists: [
        {
          value: "0",
          label: "0",
        },
        {
          value: "300",
          label: "300",
        },
        {
          value: "500",
          label: "500",
        },
      ],
      ProbationExplainLists: [
        {
          value: "0",
          label: "无",
        },
        {
          value: "7",
          label: "7天",
        },
        {
          value: "1",
          label: "1个月",
        },
        {
          value: "2",
          label: "2个月",
        },
        {
          value: "3",
          label: "3个月",
        },
        {
          value: "5",
          label: "5个月",
        },
      ],
      matchRateLists: [
        {
          value: "较高",
          label: "较高",
        },
        {
          value: "尚可",
          label: "尚可",
        },
        {
          value: "较低",
          label: "较低",
        },
        {
          value: "不符合",
          label: "不符合",
        },
      ],
      BasicLists: [
        {
          value: "较差",
          label: "较差",
        },
        {
          value: "一般",
          label: "一般",
        },
        {
          value: "良好",
          label: "良好",
        },
        {
          value: "较好",
          label: "较好",
        },
        {
          value: "优秀",
          label: "优秀",
        },
      ],
      RecommendationLists: [
        {
          value: "强烈建议",
          label: "强烈建议",
        },
        {
          value: "可安排复试",
          label: "可安排复试",
        },
        {
          value: "资料存档",
          label: "资料存档",
        },
        {
          value: "不符合",
          label: "不符合",
        },
      ],
      positionLists: [
        {
          value: "技术",
          label: "技术",
        },
        {
          value: "后勤",
          label: "后勤",
        },
        {
          value: "生产",
          label: "生产",
        },
        {
          value: "管理",
          label: "管理",
        },
        {
          value: "特聘",
          label: "特聘",
        },
      ],
      passedLists: [
        {
          value: "通过",
          label: "通过",
        },
        {
          value: "未通过",
          label: "未通过",
        },
      ],
      rules: {
        Factory: [
          { required: true, message: "请选择面试公司", trigger: "blur" },
        ],
        TalkDate: [{ required: true, message: "请选择日期", trigger: "blur" }],
        Post: [{ required: true, message: "请选择岗位", trigger: "blur" }],
        IsPassed: [ { required: true, message: "请选择是否通过", trigger: "blur" }],
        PostType: [
          { required: true, message: "请选择岗位类型", trigger: "blur" },
        ],
        TalkPersons: [
          { required: true, message: "请选择面试人员", trigger: "blur" },
        ],
        BasicAbility: [
          { required: true, message: "请输入内容", trigger: "blur" },
        ],
        ProfessionalAbility: [
          { required: true, message: "请输入内容", trigger: "blur" },
        ],
        WorkAbility: [
          { required: true, message: "请输入内容", trigger: "blur" },
        ],
        OfficeAbility: [
          { required: true, message: "请输入内容", trigger: "blur" },
        ],
        PotentialAbility: [
          { required: true, message: "请输入内容", trigger: "blur" },
        ],
        DressUp: [{ required: true, message: "请输入内容", trigger: "blur" }],
          Summarize: [{ required: true, message: "请输入内容", trigger: "blur" }],
          OldFactoryWelfare: [{ required: true, message: "请输入内容", trigger: "blur" }],
          
        Attitude: [{ required: true, message: "请输入内容", trigger: "blur" }],
        ProfessionalSoftware: [
          { required: true, message: "请输入内容", trigger: "blur" },
        ],
        CommunicateAbility: [
          { required: true, message: "请输入内容", trigger: "blur" },
        ],
      },
      postRules: {
        Post: [{ required: true, message: "请选择岗位", trigger: "blur" }],
        StartDate: [
          { required: true, message: "请选择开始时间", trigger: "blur" },
        ],
        ProbationExplain: [
          { required: true, message: "请选择试用期", trigger: "blur" },
        ],
        PostSalaryName: [
          { required: true, message: "请选择职务待遇", trigger: "blur" },
        ],
        SalaryType: [
          { required: true, message: "请选择工资类型", trigger: "blur" },
        ],
        EateSalary: [
          { required: true, message: "请选择伙食日单价", trigger: "blur" },
        ],
        WorkSalary: [
          { required: true, message: "请输入岗位工资", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
        handleSelectionChange1(val) {
          console.log(val)
      this.multipleSelection1 = val;
    },
      areaSelection() {
        console.log( this.multipleSelection1)
        this.PermissionForm.AttendGroupName = this.multipleSelection1.Name
          this.PermissionForm.AttendGroupId = this.multipleSelection1.Id
      // this.PermissionForm.AttendGroupAreaList = this.multipleSelection1;
      this.addressVisible = false;
     
      },
      inputMoney(name) {
          if (!validate.validateIsMoney(this.InterviewForm[name])) {
              this.$message({
                  message: "请输入数字类型",
                  type: "error",
                  duration: 5000,
              });
              this.InterviewForm[name] = null;
              return;
          }
      },

      selectAddress() {
      let that = this;
      this.$api.GetAllAttendGroup().then((res) => {
        console.log(res)
        this.AreaData = res.data.response;
        this.addressVisible = true;
        
      });
 
        
    
      
    },
    selectCurrentRow(val) {
      this.currentRow = val;
    },
    callFunction(item) {
      this[item.Func].apply(this, item);
    },
    //监听子组件事件
    usercallFunction(newdata) {
      console.log(newdata);
      this.PermissionForm.Post = newdata.Name;
      this.PermissionForm.DptId = newdata.DepartmentId;
      this.PermissionForm.PostId = newdata.Id;
      this.PermissionForm.JobType = newdata.WorkTypeId;
        GetDepartmentOrg({ deptId: newdata.DepartmentId }).then((res) => {
        if (res.data.code == 200) {
            if (res.data.data.deptName != null) {
                this.PermissionForm.Factory = res.data.data.deptName;
          } else {
            this.PermissionForm.Factory = "";
          }
            this.PermissionForm.Dpt = res.data.data.queryDeptName;
        } else {
          this.$message({
            message: "获取部门信息出错",
            type: "error",
            duration: 5000,
          });
        }
      });
      this.addUserVisible = false;
    },

    chooseUser() {
      this.addUserVisible = true;
    },
    inputMoney(name) {
      if (!validate.validateIsMoney(this.PermissionForm[name])) {
        this.$message({
          message: "请输入数字类型",
          type: "error",
          duration: 5000,
        });
        this.PermissionForm[name] = null;
        return;
      }
    },
    //添加试用
    addProbation() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要编辑的一行数据！",
          type: "error",
        });
        return;
      }
      console.log(row);

      if (row.UserInfoApprovalState == "试用审核中") {
        this.$message({
          message: "不允许重复添加试用",
          type: "error",
        });
        return;
      }
      if (row.IsPassed != "通过") {
        this.$message({
          message: "面试未通过！",
          type: "error",
        });
        return;
      }
      (this.PermissionForm = {
        UserName: "",
        userId: "",
        Post: "",
        PostId: "",
        DptId: "",
        StartDate: "",
        EndDate: "",
        SpecialSalary: "0",
        EateDaySalary: "",
        CareDaySalary: "0",
        OutsideSalary: "0",
        ProbationExplain: "",
        PositiveExplain: "",
        BusMoney: "",
        WorkSalary: "",
        LoveMoney: "",
        DaySalary: "",
        BankCardName: "",
        BankAddress: "",
        BankCardCD: "",
        PostSalaryId: "",
        PostSalaryName: "",
        SalaryType: "",
        SalaryTypeId: "",
        InterviewId: "",
        FileArry: [],
        IsYear: null,
        OverAgeUserAvailable: 0,
        OndutyCareSalary: 0,
        Remark: "",
        PerfSalary: 0,
        HouseSalary: 0,
        WorkerMod: 0,
        JobType: "",
         AttendGroupName:"",
      }),
        (this.PermissionForm.InterviewId = row.Id);
      this.PermissionForm.userId = row.UserId;
      this.PermissionForm.UserName = row.UserInfoUserName;
      getInfoList().then((res) => {
        this.SalaryTypeLists = res.data.response;
      });
      GetUserDis().then((res) => {
        this.PositionLists = res.data.response;
      });
      this.permissionVisible = true;
      this.key += 1;
    },
    getData(isCurrent = true) {
      if (isCurrent) {
        this.page = 1;
      }
      let para = {
        page: this.page,
        size: this.pageSize,
        Factory: this.selectForm.Factory,
        Post: this.selectForm.Post,
        result: this.selectForm.result,
        Username: this.selectForm.Username,
      };
      this.$api.GetInterviewPageList(para).then((res) => {
        console.log(res.data.response.data);
        this.total = res.data.response.pageCount;
        this.totaldata = res.data.response.dataCount;
        this.users = res.data.response.data;
        this.loading = false;
      });
    },
    getButtonList2(routers) {
      let _this = this;
      routers.forEach((element) => {
        let path = this.$route.path.toLowerCase();
        if (element.path && element.path.toLowerCase() == path) {
          _this.buttonList = element.children;
          return;
        } else if (element.children) {
          _this.getButtonList(element.children);
        }
      });
    },
    formatStartTime: function (row, colume) {
      var date = row[colume.property];
      if (date == undefined) {
        return "";
      }
      return util.formatDate.format(new Date(date), "yyyy-MM-dd");
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.page = val;
      this.getData(false);
    },
    onPermissionSubmit(formName) {
      this.$confirm("确定要提交吗？", "提示", {}).then(() => {
        this.$refs[formName].validate((valid) => {
          let para = Object.assign({}, this.PermissionForm);
          console.log(para);
          if (valid) {
            const loading = this.$loading({
              lock: true,
              text: "添加中",
              spinner: "el-icon-loading",
              background: "rgba(0, 0, 0, 0.7)",
            });
            AddProbation(para).then((res) => {
              loading.close();
              if (res.data.success) {
                this.$message({
                  message: "添加成功",
                  type: "success",
                  duration: 5000,
                });
                this.permissionVisible = false;
              } else {
                this.$message({
                  message: res.data.msg,
                  type: "error",
                  duration: 5000,
                });
              }
            });
            this.getData();
          } else {
            this.$message({
              message: "请检查必填项",
              type: "error",
              duration: 5000,
            });
          }
        });
      });
    },
    onSubmit(formName) {
      this.$confirm("确定要提交吗？", "提示", {}).then(() => {
        this.$refs[formName].validate((valid) => {
          let para = Object.assign({}, this.InterviewForm);
          if (valid) {
            UpdateInterview(para).then((res) => {
              if (res.data.success) {
                this.$message({
                  message: "修改成功",
                  type: "success",
                  duration: 5000,
                });
                this.interviewVisible = false;
                this.getData(false);
              } else {
                this.$message({
                  message: "修改失败",
                  type: "error",
                  duration: 5000,
                });
              }
            });
          } else {
            this.$message({
              message: "请检查必填项",
              type: "error",
              duration: 5000,
            });
          }
        });
      });
    },

    handleShow() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要编辑的一行数据！",
          type: "error",
        });
        return;
      }
        console.log(row);
        let para;
        para = {
            deptTypeName: "公司",
            workTypeName: "后勤"
        };
        getOrganizeList(para).then((res) => {
        this.OrganizeLists = res.data.response;
        this.interviewVisible = true;
      });
      this.operation = false;
      this.InterviewForm = Object.assign({}, row);
      this.interviewVisible = true;
    },
    handleEdit() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要编辑的一行数据！",
          type: "error",
        });
        return;
        }
        let para;
        para = {
            deptTypeName: "公司",
            workTypeName: "后勤"
        };
        getOrganizeList(para).then((res) => {
        this.OrganizeLists = res.data.response;
        this.interviewVisible = true;
      });
      this.operation = true;
      this.InterviewForm = Object.assign({}, row);
      this.interviewVisible = true;
    },
    PositionModel(id) {
      var obj = {};
      obj = this.PositionLists.find((item) => {
        //model就是上面的数据源
        console.log(item);
        return item.LeaveName === id; //筛选出匹配数据
      });
      this.PermissionForm.PostSalaryId = obj.Id;
    },
    SalaryTypeModel(id) {
      var obj = {};
      obj = this.SalaryTypeLists.find((item) => {
        //model就是上面的数据源
        return item.Type === id; //筛选出匹配数据
      });

      this.PermissionForm.SalaryTypeId = obj.Id;
    },
    ProbationExplainModel(id) {
      var starttime = this.PermissionForm.StartDate;
      if (starttime.length == 0) {
        this.$message({
          message: "请先选择开始时间",
          type: "error",
          duration: 5000,
        });
        this.PermissionForm.ProbationExplain = "";
      } else {
        let n = parseInt(id);
        console.log(n);
        let d = new Date(starttime);

        if (n == 7) {
          d.setDate(d.getDate() + n);
        } else {
          console.log(d.getMonth());
          d.setMonth(d.getMonth() + n);
        }
        let year = d.getFullYear();
        var mon = d.getMonth() + 1;
        var day = d.getDate();
        this.PermissionForm.EndDate = year + "-" + mon + "-" + day;
      }
    },
    dealFiles(data) {
      console.log(data.backData);
      this.PermissionForm.FileArry = data.backData;
      // this.fileList = data.backData;
      // console.log('dealFiles-list:' + this.fileList);
    },
  },
  mounted() {
    this.getData();
    let routers = window.localStorage.router
      ? JSON.parse(window.localStorage.router)
      : [];
    let buttons = window.localStorage.buttList
      ? JSON.parse(window.localStorage.buttList)
      : [];
    this.buttonList = getButtonList(this.$route.path, routers, buttons);
  },
};
</script>
<style>
.el-divider--horizontal {
  display: block;
  height: 1px;
  width: 100%;
  margin: 9px 0;
}
</style>
<style scoped>
.el-table__body tr.current-row > td {
  background: #fabe64 !important;
}
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}
</style>
